import React from "react";
import styled from "styled-components";
import { HomeHero } from "../components/Heros";
// import { Header, Body, TextBlock } from "../components/Typography";
import { Colors } from "../theme/Colors";
import { SiteContent, TextImageBlock } from "../components/Layouts";
import Seo from "../components/seo";
import { HomePageData } from "../data/home-page";
import { LargeCard, ServiceCard } from "../components/Cards";
import "../theme/css/animations.css";
import "animate.css/animate.min.css";

const Wrapper = styled.div`
  overflow: hidden;
`;

const HomeWrapper = styled.div`
margin-top: 80px;
  height: 100%;
  background-color: ${Colors.White};
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const LargeCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;


const IndexPage = (className) => {
  const { hero, services, indsustries, textblocks } = HomePageData;

  return (
    <Wrapper>
      <HomeWrapper>
        <Seo title="VASTech Solutions — WNY" />
        {/* Hero */}

        <HomeHero data={hero} />
        <ContentWrapper>
          {/* Services */}
          <div>
            <SiteContent id="intro">
              <ServicesWrapper>
                {services.service.map((data, index) => {
                  return (
                    <ServiceCard data={data} index={index} key={'serviceCard' + index} />
                  );
                })}
              </ServicesWrapper>
            </SiteContent>
          </div>

          {/* Industries */}
          <SiteContent>
            <LargeCardWrapper>
              {indsustries.map((data, index) => {
                return (
                  <LargeCard
                    key={'induystryCard' + index}
                    data={data}
                    index={index}
                  />
                );
              })}
            </LargeCardWrapper>
          </SiteContent>

          <SiteContent column>
            {textblocks.map((data, index) => {
              return (
                <TextImageBlock data={data} index={index} key={'text-image-' + index}/>
              );
            })}
          </SiteContent>

          {/* Testimonials */}
          {/* <SiteContent style={{ zIndex: 2, textAlign: "center" }} center>
            <Quote
              data-aos="zoom-in"
              data-aos-duration={800}
              data-aos-delay={400}
              data-aos-once="true"
              alt="quotation mark"
              src={QuoteSvg}
            />
            <TextBlock
              textAlign="center"
              colorSwap
              color={Colors.Purple}
              subheader={testimonials.subheader}
              title1={testimonials.title1}
              title2={testimonials.title2}
              body={testimonials.body}
            />
          </SiteContent>
          <TestimonialSlider data={testimonials.testimonial} /> */}

        </ContentWrapper>


      </HomeWrapper>
    </Wrapper>
  );
};

export default IndexPage;
