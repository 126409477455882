import React from "react";
import styled from "styled-components";
import { Body, SubHeader } from "../Typography";
import HomeHeroImage from "../../images/home/vastech-bg.webp";
import { SiteContent } from "../Layouts";
import { ButtonPrimary, NewLink } from "../Buttons";
import { Breakpoints, Colors } from '../../theme';

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
  min-height: 700px;
  justify-content: center;
  align-items: center;
  background: url(${HomeHeroImage});
  background-size: cover;
  background-position: bottom;
  margin-bottom: 128px;
  position: sticky;
  transform: scale(1);
  border-radius: 12px;
  margin: 32px;
  /* background-color: white; */

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    margin: 16px;
  }
`;

const ContentWrapper = styled.div`
  padding-bottom: 64px;
  max-width: 780px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Spacer = styled.div`
  width: 32px;
`;

const HeroH1 = styled.h1`
  font-size: 64px;
  margin: 0;
  margin-bottom: 16px;
  color: white;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    font-size: 48px;
    margin-bottom: 8px;
  }

  @media only screen and (max-width: ${Breakpoints.mobile}) {
    font-size: 36px;
  }
`;

const HomeHero = ({ data }) => {

  return (
    <HeroWrapper>
      <SiteContent column>
        <ContentWrapper>
          <SubHeader
            data-aos="fade-down"
            data-aos-duration={800}
            fontSize={18}
            thin
          >
            {data.subheader}
          </SubHeader>
          <div style={{ height: "16px" }}></div>
          <HeroH1
            data-aos="fade-in"
            data-aos-duration={1200}
            data-aos-delay="400"
          >
            {data.title}
          </HeroH1>
          {/* <Header h1 margin="0 0 32px 0">
            Digital
          </Header> */}
          <Body
            data-aos="fade-up"
            data-aos-duration={800}
            data-aos-delay="800"
            maxWidth={800}
            fontSize={26}
            lineHeight={39}
            align="center"
            color="white"
          >
            {data.body}
          </Body>

        </ContentWrapper>
        <CTAWrapper>
          {data.buttonTitle &&
            <ButtonPrimary
              marginAuto
              href={'https://clienthub.getjobber.com/client_hubs/c1a25f75-f243-439f-ab61-637e781c1d7e/public/work_request/new?source=social_media'}
              title={'Contact Us'}
              target="_blank"
            />
          }
          <Spacer />
          {data.linkTitle &&
            <NewLink
              color={Colors.White}
              to={data.linkRoute}>{data.linkTitle}</NewLink>
          }
        </CTAWrapper>

      </SiteContent>
    </HeroWrapper>
  );
};

export default HomeHero;
